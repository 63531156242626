<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-base sm:font-semibold text-center mt-6">Ihre Bewerbung</p>
      <p class="text-xs sm:text-sm text-center">Ihre Bewerbung ist bereits aktiv und wird für einen Monat publiziert. Sie können Sie hier bearbeiten und vervollständigen.</p>
    </template>

    <div class="panel">
      <div class="grid grid-cols-1 sm:grid-cols-2 sm:gap-3">


      <form v-if="formdata.hasOwnProperty('dynamic_form')" @submit="onSubmit">

        <div v-for="field in inputFields" :key="field.id_int?? field.id">
          <div v-if="field.type !== 'seperator'" class="md:grid md:grid-cols-4 mt-3">
            <label v-if="field.type !== 'checkboxField'" :for="field.id_int?? field.id" class="text-right text-sm text-gray-500 px-2 pt-4">{{
                field.label
              }}</label>
            <div class="col-span-3 col-start-2">
              <component :is="field.type"
                         v-model:formdata="formdata"
                         v-model:validation="validation"
                         :field="field"
                         :is-visible="true"
                         mode="editContent"
                         :option-fields="field.type === 'selectField' || field.type === 'radioField' ? fields : null"
                         :postcodes="field.type === 'zipCityStaticField' || field.type === 'zipCityField' ? postcodes : null"
              />
            </div>
          </div>
        </div>

        <div class="flex justify-center mt-3">
          <button class="btn btn-lg btn-primary" data-cy="btn-submit" type="submit">{{ $t('general.save') }}</button>
        </div>
      </form>
        <div v-if="jobApplication.hasOwnProperty('id')">
          <div class="font-semibold pt-2 mb-4">Profilbild hochladen</div>
          <media-library-attachment name="profile"
                                    :validation-rules="{ accept: ['image/png', 'image/jpeg'], maxSizeInKB: 5000 }"
                                    :upload-domain="backendUrl"
                                    :route-prefix="'lead-form/job-applications/' + jobApplication.id + '/media/profile'"
                                    :initial-value="profile"
          />
          <div class="font-semibold pt-10 mb-4">Weitere Dateien anhängen (Lebenslauf, etc.)</div>
          <media-library-collection name="files"
                                    :validation-rules="{ accept: ['application/pdf'], maxSizeInKB: 5000 }"
                                    :upload-domain="backendUrl"
                                    :route-prefix="'lead-form/job-applications/' + jobApplication.id + '/media/files'"
                                    :initial-value="files"
                                    @is-ready-to-submit-change="isReadyToSubmit = $event"
                                    @change="onChangeFiles"
          />
          <button v-if="isReadyToSubmit"
                  class="btn btn-primary btn-sm mt-4"
                  @click="changeMedia"
          >
            {{ $t('general.submit') }}
          </button>
        </div>
      </div>
    </div>
  </layout>
</template>

<script>

import { defineComponent } from 'vue';
import CircleButton from '../../snippets/CircleButton.vue';
import Layout from '../../Layout.vue';
import store from '../../store/mainStore';
import locationApi from '../../connections/location';
import leadFormApi from '../../connections/leadForm';
import ZipCityStaticField from '../../formcreator/fieldComponents/zipCityStaticField.vue';
import EmailStaticField from '../../formcreator/fieldComponents/emailStaticField.vue';
import PhoneStaticField from '../../formcreator/fieldComponents/phoneStaticField.vue';
import ForenameStaticField from '../../formcreator/fieldComponents/forenameStaticField.vue';
import SurnameStaticField from '../../formcreator/fieldComponents/surnameStaticField.vue';
import AddressStaticField from '../../formcreator/fieldComponents/addressStaticField.vue';
import DateField from '../../formcreator/fieldComponents/dateField.vue';
import CheckboxField from '../../formcreator/fieldComponents/checkboxField.vue';
import SelectField from '../../formcreator/fieldComponents/selectField.vue';
import TextareaField from '../../formcreator/fieldComponents/textareaField.vue';
import SalutationStaticField from '../../formcreator/fieldComponents/salutationStaticField.vue';
import { MediaLibraryAttachment } from '@spatie/media-library-pro-vue3-attachment';
import { MediaLibraryCollection } from '@spatie/media-library-pro-vue3-collection';
export default defineComponent({
  name: 'EditJobApplication',
  components: { Layout, CircleButton, ZipCityStaticField, EmailStaticField, PhoneStaticField, ForenameStaticField, SurnameStaticField, AddressStaticField, DateField, CheckboxField, SelectField, TextareaField, SalutationStaticField, MediaLibraryAttachment, MediaLibraryCollection },
  data() {
    return {
      backendUrl: import.meta.env.VITE_VUE_APP_BACKEND_API_URL,
      isReadyToSubmit: false,
      jobApplication: {},
      loading: true,
      fields: [],
      locale: 'de-CH',
      profile: [],
      files: [],
      media: [],
      validation: {
        form: false,
      },
      formdata: {},
    }
  },
  methods: {
    changeMedia() {
      leadFormApi.patch('job-applications/' + this.jobApplication.id + '/media/files/uploads', { payload: this.media }, { notification: true }).then(response => this.collection = '').catch()
    },
    onChangeFiles(media) {
      this.media = media
    },
    onSubmit(evt) {
      evt.preventDefault();

      leadFormApi.patch('/users/'+ this.$route.params.user_id +'/job-application', this.formdata, { notification: true, progress: true });
    },
  },
  computed: {
    loading() {
      if (this.postcodes.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    postcodes() {
      return store.state.main.postcodes;
    },
    inputFields() {
      if(this.fields.length > 0) {
        let fields = this.fields;
        fields = fields.filter(obj => obj.parent === null && obj.type !== 'tabsFormField');
        fields.forEach(obj => obj.id_int = obj.id)
        fields.forEach(obj => obj.identification ? obj.id = obj.identification : '')
        return fields;
      } else {
        return []
      }
    },
  },
  created() {
    store.dispatch('getPostcodes', null);
    leadFormApi.get('/forms/multiple-forms-and-fields/35?locale=' + this.locale)
      .then(response => [
        this.fields = response.data.content['fields'][35],
        this.loading = false])
    this.formdata = JSON.parse(JSON.stringify(store.state.main.user))
    leadFormApi.get('/users/'+ this.$route.params.user_id +'/job-application')
      .then(response => [
        this.jobApplication = response.data.content['jobApplication'], this.profile = response.data.content['profile'], this.files = response.data.content['files'], this.formdata.dynamic_form = response.data.content['jobApplication'], this.formdata.salutation = response.data.content['jobApplication'].salutation])
  }
});
</script>

<style scoped>

</style>