<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-base sm:font-semibold text-center mt-6">Jobbörse</p>
      <p class="text-xs sm:text-sm text-center">Falls Sie nach neuen Mitarbeitern suchen, finden Sie hier verschiedene Ausschreibungen. </p>
    </template>

    <div v-if="!loading" class="job-board grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 pt-5">
      <div v-for="application in applicants" :key="application.id" class="applicant-card border rounded-lg p-6 bg-white shadow-md">
        <div class="profile-section flex gap-4">
          <div v-if="profilePicture(application)" class="relative">
            <!-- Bild mit Hover-Effekt -->
            <img
              :src="profilePicture(application)"
              alt="Profilbild"
              class="profile-picture w-20 rounded object-cover transition-transform duration-300 ease-in-out hover:scale-110 cursor-pointer"
              @click="showImageInModal(profilePicture(application))"
            />
          </div>
          <div class="contact-info">
            <h2 class="text-lg font-semibold">{{ application.user.name }} {{ application.user.surname }}</h2>
            <p class="text-sm text-gray-500">
              {{ application.user.postcode }} {{ application.user.city }}
            </p>
            <p class="text-sm text-gray-500">
              <a :href="`mailto:${application.user.email}`" class="text-blue-500 hover:underline">{{ application.user.email }}</a>
            </p>
            <p class="text-sm text-gray-500">
              <a :href="`tel:${application.user.phone}`" class="text-blue-500 hover:underline">{{ application.user.phone }}</a>
            </p>
          </div>
        </div>

        <div class="job-info mt-4">
          <h3 class="text-md font-medium">Job Interessen</h3>
          <ul class="list-disc list-inside">
            <li v-if="application.cleaning">Reinigungsjob</li>
            <li v-if="application.moving">Umzugsjob</li>
            <li v-if="application.painting">Malerarbeiten</li>
          </ul>
          <p class="text-sm text-gray-600 mt-2"><strong>Pensum:</strong> {{ workload(application) }}</p>
          <p class="text-sm text-gray-600"><strong>Geburtsdatum:</strong> {{ formatDate(application.date_of_birth) }}</p>
          <p v-if="application.notes.length > 0" class="text-sm text-gray-600"><strong>Bemerkungen:</strong> {{ application.notes }}</p>
        </div>

        <div v-if="files(application)" class="attachments mt-4">
          <h3 class="text-md font-medium">Angehängte Dokumente</h3>
          <ul class="list-none">
            <li v-for="file in files(application)" :key="file.name">
              <a :href="file.original_url" target="_blank" class="text-blue-500 hover:underline">{{ file.name }}</a>
            </li>
          </ul>
        </div>
      </div>

      <!-- Modal für Bildanzeige -->
      <div v-if="showModal" class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
        <div class="bg-white p-6 rounded-lg relative">
          <button @click="showModal = false" class="absolute top-2 right-2 text-gray-600 hover:text-gray-900">
            &times;
          </button>
          <img :src="modalImage" alt="Profilbild" class="w-64 h-64 object-cover rounded-lg" />
        </div>
      </div>
    </div>
  </layout>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import leadFormApi from '../../../components/connections/leadForm.js';
    import Layout from "../../Layout.vue";

    export default defineComponent({
        name: 'JobBoard',
        components: { Layout },
        data() {
            return {
                locale: 'de-CH',
                applicants: [],
                showModal: false,
                modalImage: '',
                loading: true,
                fields: [],
            };
        },
        methods: {
            formatDate(date: string) {
                const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
                return new Date(date).toLocaleDateString('de-DE', options);
            },
            profilePicture(application) {
                if (application.media.find(obj => obj.collection_name === 'profile')) {
                    return application.media.find(obj => obj.collection_name === 'profile').preview_url;
                } else {
                    return null;
                }
            },
            files(application) {
                if (application.media.find(obj => obj.collection_name === 'files')) {
                    return application.media.filter(obj => obj.collection_name === 'files');
                } else {
                    return null;
                }
            },
            showImageInModal(imageUrl) {
                this.modalImage = imageUrl;
                this.showModal = true;
            },
            workload(application) {
                return this.fields.find(obj => obj.id === application.workload).label
            }
        },
        created() {
            leadFormApi.get('/forms/multiple-forms-and-fields/35?locale=' + this.locale)
                .then(response => [
                    this.fields = response.data.content['fields'][35],
                    this.loading = false])
            leadFormApi.get('/job-applications')
                .then(response => {
                    this.applicants = response.data.content;
                });
        },
    });
</script>

<style scoped>
</style>
