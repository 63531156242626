<template>
  <div class="container">
    <div v-if="validationErrors">
      <div class="w-full">
        <ul class="alert alert-danger">
          <li v-for="(value, key) in validationErrors" :key="key">@{{ value }}</li>
        </ul>
      </div>
    </div>
    <div>
      <div class="w-full">
        <a id="form" name="form" />
        <div class="flex justify-center mt-2 mb-3" :class="formSteps.length > 1 ? 'md:justify-between' : 'md:justify-end'">
          <div v-if="formSteps.length > 1" class="flex">
            <div v-for="step in formSteps"
                 :key="'step' + step.value"
                 :class="{ 'step':true, 'active':(step.active), 'hidden':(!step.display), 'invalid':(show.validation && step.invalid) }"
                 @click="currentStep = step.step"
            >
              {{ step.value }}
            </div>
          </div>

          <div v-if="formType === 'compact'"
               class="hidden md:block text-right text-xs"
               :class="{ 'w-full':(formSteps.length === 0)}"
               v-html="$t('topofferten.formContainer.spoiler')"
          />
        </div>
      </div>
    </div>
    <div v-if="show.validation">
      <div><p class="required-fields">{{ $t('topofferten.formContainer.requiredFieldsInfo') }}</p></div>
    </div>

    <form :id="form.name"
          :class="{ 'mainform-validated':(show.validation) }"
          class="main"
          @submit="onSubmit"
    >
      <div v-if="inputFields.length > 0" class="flex flex-wrap">
        <component :is="field.type"
                   v-for="field in inputFields"
                   :key="'field' + field.id"
                   :ref="'component' + field.id"
                   v-model:formdata="formdata"
                   v-model:calc="calc"
                   v-model:validation="validation"
                   :field="field"
                   :is-visible="field.step === currentStep"
                   :mode="mode"
                   :option-fields="field.type === 'selectField' || field.type === 'selectFormField' || field.type === 'radioField' ? optionFields.filter(value => value.parent === field.id) : []"
                   :postcode="field.type === 'zipCityStaticField' ? postcode : null"
                   :postcodes="field.type === 'zipCityStaticField' || field.type === 'zipCityField' ? postcodes : []"
                   :data-gtm="'field_' + field.id"
                   @field-on-focus="getFieldOnFocus"
                   @new-fields="newFields"
        />
      </div>
      <div v-else class="flex justify-center mt-3">
        <div class="text-center">{{ $t('topofferten.formContainer.outOfService') }}</div>
      </div>
      <div v-if="show.validation">
        <div><p class="text-center required-fields">{{ $t('topofferten.formContainer.requiredFieldsInfo') }}</p></div>
      </div>
      <div v-if="formType !== 'calculator'">
        <div class="flex justify-center mt-3 px-2">
          <button v-if="stepsAhead"
                  id="formNext"
                  type="submit"
                  class="btn btn-lg btn-primary"
          >
            {{ $t('general.next') }}
          </button>
          <!-- IMPORTANT: id formSubmit MUST NOT BE CHANGED. Our partner eTaktiker uses this in whatconverts.com to track the leads -->
          <!-- checkFullForm is done twice, otherwise the fields are not turning red if browser identifies an empty required field -->
          <button v-else
                  :id="whatConvertsValidLead ? 'formSubmit' : 'do-not-track'"
                  type="submit"
                  class="btn btn-lg btn-primary w-full sm:w-auto"
                  @click="checkFullForm(false)"
          >
            {{ $t('topofferten.formContainer.getQuotes', {desired_quotes: formdata.desired_quotes}) }}
          </button>
        </div>

        <div class="flex justify-center mt-3">
          <button v-if="currentStep > 1"
                  id="formBack"
                  class="btn btn-sm btn-secondary"
                  @click="stepBack"
          >
            {{ $t('general.back') }}
          </button>
        </div>
        <div class="flex justify-center mt-3">
          <div class="text-center">
            {{ $t('topofferten.formContainer.bottomText', {desired_quotes: formdata.desired_quotes}) }}
          </div>
        </div>
      </div>
      <div v-else-if="inputFields.length > 0" class="flex-col justify-center text-center">
        <div class="text-center">
          <span class="relative overflow-visible bg-yellow-200 px-4 pt-4 pb-3">
            <span class="relative font-bold text-xl">CHF {{ priceCalculated }}</span>
          </span>
        </div>
      </div>

      <div v-if="show.explainDate === true" class="absolute inset-0 flex flex-col items-center justify-center bg-black/70 rounded z-20">
        <div class="p-4 rounded shadow-lg bg-white text-center text-base border mx-4">
          <div v-html="$t('topofferten.formContainer.dateExplained')" />
          <div class="flex justify-center mt-4">
            <button class="btn btn-primary btn-sm" @click="show.explainDate = false">{{ $t('general.close') }}</button>
          </div>
        </div>
      </div>

      <div v-if="show.send" class="absolute inset-0 flex flex-col items-center justify-center bg-white/90 z-20">
        <p v-if="error.status" class="text-sm font-semibold text-center">{{ error.message }}</p>
        <p v-else-if="form.use_company_finder" class="text-sm font-semibold text-center">{{ $t('topofferten.formContainer.ThatsIt') }}</p>
        <p v-else class="text-sm font-semibold text-center">{{ $t('topofferten.formContainer.oneMoment') }}</p>
        <div class="relative h-10 w-10 mt-5">
          <div class="absolute text-center left-0 right-0 top-2.5">
            {{ timerCount }}
          </div>
          <div class="sk-chase">
            <div class="sk-chase-dot sk-chase-dot-1" />
            <div class="sk-chase-dot sk-chase-dot-2" />
            <div class="sk-chase-dot sk-chase-dot-3" />
            <div class="sk-chase-dot sk-chase-dot-4" />
            <div class="sk-chase-dot sk-chase-dot-5" />
            <div class="sk-chase-dot sk-chase-dot-6" />
            <div class="sk-chase-dot sk-chase-dot-7" />
          </div>
        </div>

        <div v-if="error.status" class="flex justify-center mt-4">
          <button id="error" class="btn btn-secondary btn-sm" @click="closeError">{{ $t('general.close') }}</button>
        </div>
        <div v-else-if="timerCount === 0" class="flex justify-center mt-4">
          <button class="btn btn-secondary btn-sm" @click="closeError">{{ $t('general.next') }}</button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import CheckboxField from './fieldComponents/checkboxField.vue';
import NumberField from './fieldComponents/numberField.vue';
import RadioField from './fieldComponents/radioField.vue';
import SelectField from './fieldComponents/selectField.vue';
import TextField from './fieldComponents/textField.vue';
import TitleText from './fieldComponents/titleText.vue';
import TextareaField from './fieldComponents/textareaField.vue';
import DateField from './fieldComponents/dateField.vue';
import ZipCityStaticField from './fieldComponents/zipCityStaticField.vue';
import EmailStaticField from './fieldComponents/emailStaticField.vue';
import PhoneStaticField from './fieldComponents/phoneStaticField.vue';
import ForenameStaticField from './fieldComponents/forenameStaticField.vue';
import SurnameStaticField from './fieldComponents/surnameStaticField.vue';
import AddressStaticField from './fieldComponents/addressStaticField.vue';
import DateStaticField from './fieldComponents/dateStaticField.vue';
import TextareaStaticField from './fieldComponents/textareaStaticField.vue';
import LocalMetaField from './fieldComponents/localMetaField.vue';
import PriceQualityMetaField from './fieldComponents/priceQualityMetaField.vue';
import QuotesMetaField from './fieldComponents/quotesMetaField.vue';
import FileField from './fieldComponents/fileField.vue';
import leadFormPreviewApi from '../connections/leadFormPreview';
import ZipCityField from './fieldComponents/zipCityField.vue';
import Seperator from './fieldComponents/seperator.vue';
import * as packageJson from '../../../package.json'
import FlexibleDateStaticField from './fieldComponents/flexibleDateStaticField.vue';
import SalutationStaticField from './fieldComponents/salutationStaticField.vue';
import AvailabilityStaticField from './fieldComponents/availabilityStaticField.vue';

export default {
  name: 'FormContainer',
  components: {
    FlexibleDateStaticField,
    Seperator,
    ZipCityField,
    FileField,
    QuotesMetaField,
    PriceQualityMetaField,
    LocalMetaField,
    ZipCityStaticField,
    EmailStaticField,
    PhoneStaticField,
    ForenameStaticField,
    SurnameStaticField,
    AddressStaticField,
    DateStaticField,
    SelectField,
    DateField,
    TextareaStaticField,
    TextareaField,
    TextField,
    CheckboxField,
    RadioField,
    NumberField,
    TitleText,
    SalutationStaticField,
    AvailabilityStaticField
  },
  props: {
    // Input fields, Options, Titles
    form: {
      type: Object,
      default() {
          return {};
      },
    },
    fields: {
      type: Array,
      default: () => [],
    },
    postcodes: {
      type: Array,
      default: () => [],
    },
    mode: {
        type: String,
        default: 'live',
    },
    domainForm: {
      type: Object,
      default() {
        return {
          preselection: {},
        };
      },
    },
    postcode: {
      type: Number,
      default: null,
    },
    sessionId: {
      type: String,
      default: 'form-container-default',
    },
    frontendId: {
      type: String,
      default: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15),
    },
    locale: {
      type: String,
      default: 'de-CH',
    },
      isMobile: {
          type: Number,
          default: null,
      },
      desiredCompany: {
          type: Number,
          default: null,
      },
    formType: {
      type: String,
      default: 'compact',
    },
  },
  emits: ['field-on-focus'],
  data() {
    return {
      currentStep: 1,
      formSteps: [],
      validation: {
        form: false,
      },
      timerCount: 0,
      formdata: {
        // is important for the registration of the form in the DB
        form_id: this.form.id,
        step: 1,
        postcode: '',
        city: '',
        postcode_id: Number,
        country: '',
        locale: this.locale,
        salutation: '',
        name: '',
        surname: '',
        address: '',
        phone: '',
        availability: '',
        email: '',
        date: '',
        flexible_date: true,
        description: '',
        desired_quotes: [1, 3].includes(this.form.id) ? '4' : '3',
        local: 0.5,
        price_quality: 0.5,
        files: [],
        showHide: '',
          desired_company: this.desiredCompany,
        dynamic_form: {},
        random_id: this.frontendId,
        session_id: this.sessionId,
        logCallSending: false,
        mobile: this.isMobile,
      },
      validationErrors: '',

      show: {
        validation: false,
        send: false,
        explainDate: false
      },
      formName: '',
      error: {
        status: false,
        message: 'Der Server konnte nicht erreicht werden. Bitte versuchen Sie es später nochmals.',
      },
      calc: {},

      whatConvertsValidLead: false, // this is only needed for the eTaktiker Conversion Tracking.
    };
  },
  computed: {
    // Input fields and Titles, but no options (for select and radio)
    inputFields() {
      let fields = this.fields;
      if (this.formType === 'compact') {
        fields = fields.filter(obj => obj.extended_form === null);
      } else if (this.formType === 'calculator') {
        fields = fields.filter(obj => obj.price_calc_form !== null);
      }
      return fields.filter(obj => obj.parent === null && obj.type !== 'tabsFormField' && obj.step === this.currentStep);
    },
    optionFields() {
      let fields = this.fields;
      if (this.formType === 'calculator') {
        fields = fields.filter(obj => obj.price_calc_form !== null);
      }
      return fields.filter(obj => obj.parent !== null);
    },
    // Returns the amount of steps of the current form
    amountOfSteps() {
      return Math.max.apply(Math, this.fields.filter(obj => !obj.parent).map(function(o) {
        return o.step;
      }));
    },
    // If there are displayed steps ahead it returns true
    stepsAhead: function() {
      if (this.formSteps.filter(item => item.step > this.currentStep && item.display === true).length > 0) {
        return true;
      } else {
        return false;
      }
    },
    priceCalculated() {
      let calc = this.form.price_calc_base;
      if (this.formType === 'calculator' && Object.values(this.calc).length > 0) {
        Object.values(this.calc).forEach(value => calc = value.length > 0 ? value[0] === '+' ? calc + value : value + '*' + calc : calc + '');
      }
      return parseInt(eval(calc));
    },
  },
  watch: {
    fields: function(newVal) {
      if (newVal[0]) {
        this.formdata.form_id = newVal[0].form_id;
        // this.formdata.dynamic_form = { ...this.formdata.dynamic_form, ...JSON.parse(this.domainForm.preselection) };
        this.validation = {};
        this.getFormSteps();
      }
    },
    formdata: {
      immediate: true,
      handler() {
        if (this.formdata.name === 'testanfrage' || this.formdata.surname === 'testanfrage') {
          this.whatConvertsValidLead = false; // to not report it to what-converts.com (eTatiker Partner)
        } else {
          if (!this.stepsAhead && Object.values(this.validation).find(obj => obj.step === this.currentStep) && !Object.values(this.validation).find(obj => obj.valid === false)) {
            this.whatConvertsValidLead = true;
          } else {
            this.whatConvertsValidLead = false;
          }
        }
        this.getFormSteps();
      }, deep: true,
    },
    currentStep: function(newVal) {
      this.formdata.step = newVal;
    },
    isMobile: function(newVal) {
      this.formdata.mobile = newVal;
    },
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.formName = this.form.name;
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        'event': 'show_form',
        'form_id': this.form.id,
        'form_name': this.form.name,
        'form_type': this.formType,
        'company_id': this.desiredCompany,
    });
    console.log('FormContainer - App Version: (my/package.json): ', packageJson.version);
    this.logStep('mounted', false);
  },
  methods: {
    validateFormStep() {
      let invalidFields = Object.values(this.validation).find(obj => obj.step === this.currentStep && obj.valid === false);
      if (invalidFields) {
        this.show.validation = true;
        this.validation.form = true;
        this.scrollToTop(false);
        this.logStep('validateFormStep', false, invalidFields.key);
        if(this.fields.find(obj => obj.id === invalidFields.key).type === 'dateStaticField') {
          this.show.explainDate = true;
          this.logStep('explainDate', false);
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event' : 'form_validation_error',
          'form_id' : this.form.id,
          'form_name' : this.form.name,
          'field_id' : invalidFields.key,
          'step' : this.currentStep,
        });
        return false;
      } else {
        this.show.validation = false;
        this.validation.form = false;
        this.logStep('validateFormStep', true);
        return true;
      }
    },
    onSubmit(evt) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event' : 'form_submit_try',
        'form_id' : this.form.id,
        'form_name' : this.form.name,
      });

      evt.preventDefault();
      if (this.validateFormStep() === false){
        return;
      }
      if (this.currentStep < this.amountOfSteps && this.formSteps.find(obj => obj.step > this.currentStep && obj.display === true)) {
        this.currentStep = this.formSteps.find(obj => obj.step > this.currentStep && obj.display === true).step;
        this.getFormSteps();
        this.scrollToTop(true);
        window.dataLayer.push({
          'event' : 'form_validation_error',
          'form_id' : this.form.id,
          'form_name' : this.form.name,
          'type' : 'more-steps',
          'step' : this.currentStep,
        });
        return;
      }

      if (!this.checkFullForm()) {
        return;
      }
      this.show.send = true;

      // Strange, but this is needed, otherwise the lead is tracked in what-converts.com
      // I don't understand it, as this.whatConvertsValidLead is set to false before, but I tested
      // it multiple times with my what-converts.com testing account. 06.2024 Andi
      if (this.formdata.name === 'testanfrage' || this.formdata.surname === 'testanfrage') {
        this.whatConvertsValidLead = false; // for testing: Change the id of the form to not report it to what-converts.com (eTaktiker Partner)
        window.dataLayer.push({
          'event' : 'form_test_anfrage',
          'form_id' : this.form.id,
          'form_name' : this.form.name,
        });
      }

      // delete all unseen properties from dynamic_form, eval() does not work in the forEach loop
      this.fields.filter(obj => obj.parent === null && obj.static === 0).forEach(obj => this.deleteProperty(obj));

      this.timerCount = 20;
      let form = new FormData();
      if (Object.prototype.hasOwnProperty.call(this.formdata, 'files')) {
        for (var i = 0; i < this.formdata.files.length; i++) {
          let file = this.formdata.files[i];
          form.append('files[' + i + ']', file);
        }
        delete this.formdata.files;
      }
      Object.keys(this.formdata).forEach(key => key === 'dynamic_form' ? form.append(key, JSON.stringify(this.formdata[key])) : form.append(key, this.formdata[key]));
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };

      function gtag_report_conversion(url, data) {
        var callback = function () {
          console.log("Callback - switch to new url");
          if (typeof(url) != 'undefined') {
            window.location.href = url;
          } else {
            this.timerCount = 0;
          }
        };

        // Set a timeout to trigger the callback after 5 seconds - necessary if the browser blocks the push
        var timeoutId = setTimeout(callback, 5000);

        try {
          // GTM Event, used for Ads Conversion Tracking
          window.dataLayer.push({
            'event': 'generate_lead',
            'form_id' : data.content['form_id'] ?? 0,
            'form_name' : data.content['form_name'] ?? 0,
            'leadValue': data.content['obsc'] - 77777 ?? 1.0,
            'leadUuid': data.content['lead_uuid'] ?? 'xxxxxxxx',
            'leadEmail': data.content['user_email'] ?? 'error@error.ch',
            'event_callback': function() {
              clearTimeout(timeoutId); // Clear the timeout if the event callback is triggered before 5 seconds
              callback();
            },
            'eventTimeout' : 3000
          });
        } catch (error) {
          console.log(error);
        }

        return true;
      }

      leadFormPreviewApi.post('/leads', form, config).then(response => (response && this.mode === 'create') ? '' :
        gtag_report_conversion(leadFormPreviewApi.myUrl
          + '/customers/' + response.data.content['user_id']
          + '/leads/' + response.data.content['lead_uuid']
          + '/finish-lead'
          + '?session_id=' + this.sessionId
          + '&status=' + response.data.notificationType
          + '&autologin_token=' + response.data.content['autologin_token'], response.data, this.form.id, this.form.name)).catch(error => {
        if (error && error.response && error.response.status === 422) { // Validation error
          this.validationErrors = error.response.data.errors;
          window.dataLayer.push({
            'event' : 'form_validation_error',
            'form_id' : this.form.id,
            'form_name' : this.form.name,
            'type' : 'backend-validation-failed',
            'errors' : this.validationErrors,
          });
        } else {
          this.error.status = true;
          this.error.message = 'Der Server konnte nicht erreicht werden. Bitte versuchen Sie es später noch einmal.';
          window.dataLayer.push({
            'event' : 'form_error',
            'form_id' : this.form.id,
            'form_name' : this.form.name,
            'type' : 'server-not-reachable',
          });
          throw new Error('Error on Sending the Lead-Form: ' + JSON.stringify(error));
        }
      });
    },
    deleteProperty(field) {
      if (this.formdata.dynamic_form.hasOwnProperty(field.id) && field.display_hide && !eval(field.display_hide)) {
        delete this.formdata.dynamic_form[field.id];
      }
    },
    logStep: function(step, valid, field = null) {
      if (this.mode === 'live' && !this.formdata.logCallSending) {
        this.formdata.logCallSending = true;
        leadFormPreviewApi.post('/leads/field/log',
            { type: 'field', random_id: this.formdata.random_id, session_id: this.formdata.session_id, field_id: field, form_id: this.form.id, data: step + this.currentStep, valid: valid });
        this.formdata.logCallSending = false;
      }
    },
    checkFullForm(log = true) {
      if (Object.values(this.validation).find(obj => obj.valid === false)) {
        this.getFormSteps();
        // Not needed, it is in this.getFormSteps(), right? 18.9.2020 Andi
        // Object.values(this.formSteps).forEach(obj => (obj.display === true && !Object.values(this.validation).find(item => item.step === obj.step)) ? obj.invalid = true : '');

        // Jumps to first invalid step
        if (this.validateFormStep() === true) {
          let invalidSteps = Object.values(this.formSteps).find(obj => obj.active === true && obj.display === true && obj.invalid === true);
          this.currentStep = invalidSteps.step;
          this.getFormSteps();
          this.scrollToTop(false);
          this.show.validation = true;
        }
        if (log) {
          this.logStep('checkFullForm', false);
        }
        return false;
      } else {
        if (log) {
          this.logStep('checkFullForm', true);
        }

        return true;
      }
    },

    closeError() {
      this.show.send = false;
      this.error.message = '';
      this.error.status = false;
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.country_id = null;
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },

    // sends the field to the FieldCreatorComponent for updating -> only important in form creating mode
    getFieldOnFocus: function(fieldOnFocus) {
      if(this.mode === 'create') {
      this.$emit('field-on-focus', fieldOnFocus);
      }
    },
    stepBack: function() {
      this.currentStep = this.formSteps.reverse().find(obj => obj.step < this.currentStep && obj.display === true).step;
      this.getFormSteps();
      this.scrollToTop();
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event' : 'form_step_back',
      });
    },
    newFields(fields) {
      this.$emit('new-fields', fields);
    },
    // this function pictures the behaviour of the form in an array -> how many steps, displayed or not displayed
    getFormSteps: function() {
      var stepArray = [{ step: 1, value: 1, display: true, active: true, invalid: Object.values(this.validation).find(obj => obj.step === 1 && obj.valid === false) ? true : false }];
      var n = 2;
      var number = 1;
      while (n <= this.amountOfSteps) {
        let step = false;
        var fields = this.fields.filter(obj => obj.step === n);
        for (var i = 0; i < fields.length; i++) {
          fields[i].display_hide && eval(fields[i].display_hide) === false ? step = false : step = true;
          if (step === true) {
            number++;
            break;
          }
        }
        if (step === true) {
          stepArray.push({
            step: n,
            value: number,
            display: true,
            invalid: Object.values(this.validation).find(obj => obj.step === n && obj.valid === false) ? true : false,
            active: n <= this.currentStep ? true : false,
          });
        } else {
          stepArray.push({ step: n, display: false, invalid: Object.values(this.validation).find(obj => obj.step === n && obj.valid === false) ? true : false });
        }
        n++;
      }
      this.formSteps = stepArray;
    },
    scrollToTop(top) {
      if (this.mode !== 'create') {
        if (top) {
          var url = location.href;
          location.href = '#form';
          history.replaceState(null, null, url);
        } else {
          const element = document.getElementById('field' + Object.values(this.validation).sort((a, b) => a.sort - b.sort).find(obj => obj.step === this.currentStep && obj.valid === false).key);
          const headerHeight = 100;
          const scrollY = window.scrollY;
          const targetPosition = element.getBoundingClientRect().top + scrollY - headerHeight;
          window.scrollTo({ top: targetPosition, behavior: "smooth" });
        }
      }
    },
  },

};

</script>

<style>

</style>
