<template>
  <div class="bg-white border rounded p-4 w-80 max-w-full m-2">
    <div class="font-semibold text-base">{{ product.name }}</div>
    <div class="text-gray-500">{{ $t('companyArea.shop.products.dimensions', { dimensions: product.dimensions }) }}</div>
    <div class="flex justify-center relative">
      <div class="w-44">
        <img v-if="product.image === 'umzugsbox.jpeg'" class="" src="../../../assets/images/topofferten/boxes/umzugsbox.jpeg">
        <img v-else-if="product.image === 'Buecherbox.jpeg'" class="" src="../../../assets/images/topofferten/boxes/Buecherbox.jpeg">
        <img v-else-if="product.image === 'Kleiderbox.jpeg'" class="" src="../../../assets/images/topofferten/boxes/Kleiderbox.jpeg">
      </div>
      <div class="bg-red-600 text-white font-semibold px-1 absolute right-0 top-2">- 10%</div>
    </div>
    <div class="text-base font-semibold">{{ $t('companyArea.shop.orderNow') }}</div>
    <div v-if="discount > 0" class="text-base line-through">CHF {{ priceWithoutDiscount }}</div>
    <div class="flex items-end">
      <div class="text-2xl text-red-600 font-bold">CHF {{ price }}</div>
      <div class="font-semibold mb-1 ml-2 text-xs">{{ $t('general.perItem') }}</div>
    </div>
    <div class="font-light text-xs">{{ $t('general.vatIncluded') }}</div>
    <div>
      <input v-if="area === 'Company'"
             v-model="amount"
             type="number"
             class="rounded w-full"
      >
      <select v-else v-model="amount" class="rounded w-full">
        <option>20</option>
        <option>30</option>
        <option>40</option>
        <option>50</option>
        <option>60</option>
        <option>70</option>
        <option>80</option>
        <option>100</option>
        <option>120</option>
        <option>150</option>
      </select>
    </div>
    <div class="flex justify-center mt-5 pb-8 relative">
      <button class="btn btn-md btn-primary w-full" @click="addToCart">{{ $t('general.addToCart') }}</button>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue';
  import store from '../../store/mainStore';
  import notification from '../../connections/notification';

  export default defineComponent({
    name: 'OrderProduct',
    props: {
      product: {
        type: Object,
        default: function () {
          return {};
        },
      },
      area: {
        type: String,
        default: null,
      },
      redirect: {
        type: String,
        default: null
      }
    },
    data() {
      return {
        amount: this.area === 'Company' ? 200 : 20,
        error: false,
      };
    },
    methods: {
      addToCart() {
        if(this.amount >= 20) {
          let success = false;
          let orderProduct = { area: this.area, shop_product_id: this.product.id, amount: this.amount, price: this.price, name: this.product.name, discount: this.discount };
          store.dispatch('shop/addToCart', orderProduct).then(success = true);

          if (success) {
            notification.success('Hinzugefügt');
            if(this.redirect) {
              store.dispatch('userLoggerEvent', { event: 'ShopWidget', entityType:'Product', entityId: this.product.id})
              this.$router.push({ name: this.redirect }).catch(err => {})
            }
          }
        } else {
          notification.warning('Mindestmenge nicht erreicht');

        }

      },
    },
    computed: {
      discount() {
        return store.state.main.user.hasOwnProperty('id') ? 10 : 0
      },
      price() {
        if(this.discount > 0) {
          return this.product.shop_customer_prices[0]['discount_price'];
        } else {
          return this.product.shop_customer_prices[0]['price']
        }
      },
      priceWithoutDiscount() {
        return this.product.shop_customer_prices[0]['price']
      }
    }
  });
</script>

<style scoped>

</style>
