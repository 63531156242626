<template>
  <div v-if="products.length > 0" class="relative mt-2 rounded shadow-md p-4 bg-white">
    <div class="font-bold text-lg">Shop</div>
    <div>Als top-offerten Kunde profitieren Sie von 10% Rabatt</div>
    <div class="flex flex-col sm:flex-row xl:flex-col">
      <order-product v-for="product in products"
                     :product="product"
                     area="customer"
                     redirect="CustomerShop"
      />
    </div>
  </div>
</template>

<script>

  import store from '../../store/mainStore';
  import OrderProduct from '../../publicArea/Shop/OrderProduct.vue';

  export default {
    name: 'ShopWidget',
    components: { OrderProduct },
    props: {},
    data() {
      return {};
    },
    computed: {
      products() {
        return store.state.shop.customerProducts;
      },
    },
    watch: {},
    created() {
      if(this.products.length === 0) {
        store.dispatch('shop/getCustomerShopProducts');
      }
    },
    methods: {}
  };
</script>

<style scoped>

</style>
