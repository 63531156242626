<template>
  <div :class="[mode === 'editContent' || mode === 'extendedForm' ? 'w-full' : field.class, show ? 'showField' : 'hidden']" class="relative">
    <select :id="'field' + field.id"
            ref="field"
            v-model="selected"
            :name="createFieldName()"
            :class="{ 'bg-red-200':(validation.form && !validation[field.id].valid) }"
            :data-cy="'selectField'+field.id"
            :data-gtm-field-label="field.label"
            :data-gtm-field-id="field.id"
            :required="show && !!field.required"
            :state="labelState"
            class="mainform-color mainform-select"
            :aria-label="field.label"
            @change="editFields(), logFieldBlur($event), showHideTrigger()"
    >
      <option v-for="option in options"
              :id="option.id"
              :key="'getOption' + option.id"
              :data-cy="'selectOption'+option.id"
              :value="option.id"
      >
        {{ option.label }}
      </option>
    </select>
    <label-state-icon :label-state="labelState" :required="field.required" :select-field="true"/>
  </div>
</template>

<script>

  import { formcreatorMixin } from '../../../plugins/mixin';
  import LabelStateIcon from './LabelStateIcon.vue';

  export default {
    name: 'SelectField',
    components: { LabelStateIcon },
    mixins: [formcreatorMixin],
    props: {
      field: Object,
      optionFields: Array,
      formdata: Object,
      mode: String,
      validation: Object,
      isVisible: {
        type: Boolean,
        default: false,
      },
      calc: {
        type: Object,
        default: {},
      },
      logCallSending: Boolean,
    },
    emits: ['field-on-focus'],
    data() {
      return {
        show: false,
        labelState: this.field.required ? false : null,
        selected: '',
        options: [],
      };
    },
    watch: {
      selected(newVal) {
        this.formdata.dynamic_form[this.field.id] = newVal;
        this.calcPrice(newVal);
      },
      optionFields() {
        // used in creation mode
        this.getOptions();
      },
      formdata: {
        immediate: true,
        handler() {
          this.getIfShow();
          this.checkLabelState();
          if (['live', 'preview'].includes(this.mode)) {
            this.validateField();
          }

        }, deep: true,
      },
    },
    created() {
      let id = this.field.id_int ?? this.field.id;
      if (this.formdata.dynamic_form.hasOwnProperty(id)) {
        this.selected = this.formdata.dynamic_form[id];
      } else if (this.optionFields.find(value => value.parent === id && value.selected)) {
        this.selected = this.optionFields.find(value => value.parent === id && value.selected).id;
      } else {
        this.selected = id;
      }
      this.getOptions();
      this.calcPrice(this.selected);
    },
    methods: {
      checkLabelState() {
        if (this.field.required && this.formdata.dynamic_form.hasOwnProperty(this.field.id) === false) {
          this.labelState = false;
        } else if (this.field.required && this.formdata.dynamic_form.hasOwnProperty(this.field.id)) {
          this.labelState = this.formdata.dynamic_form[this.field.id] !== this.field.id;
        } else {
          this.labelState = null;
        }
      },
      getOptions: function () {
        let id = this.field.id_int ?? this.field.id;
        this.options = this.optionFields.filter(value => value.parent === id);
        this.options.sort(function (a, b) {
          if (a.sort_form > b.sort_form) {
            return 1;
          }
          if (a.sort_form < b.sort_form) {
            return -1;
          }
          return 0;
        });

        if (this.field.value !== 'hide') {
          // puts the label on top of the array
          let label = [this.field];
          let options = this.options;
          this.options = label.concat(options);
        }

      },
      calcPrice(newVal) {
        if (this.options.filter(value => value.id === newVal).length > 0 && this.options.find(value => value.id === newVal).price_calc_form !== null) {
          this.calc[this.field.id] = this.options.find(value => value.id === newVal).price_calc_form;
        }
      },
    },
  };
</script>

