<template>
  <layout :bg-image-mobile="true">
    <template v-slot:header>
      <p class="sm:text-base sm:font-semibold text-center mt-6">Job Bewerbung</p>
    </template>

    <div class="max-w-lg mx-auto bg-white p-8 mt-10 rounded-lg shadow-lg mb-8">
      <h1 class="text-2xl font-bold mb-6">Bewerbungsformular</h1>
      <p class="my-4">Falls Sie eine Arbeit suchen im Bereich Umzug, Reinigung oder Maler, können Sie Ihre Personalien hier angeben und wir veröffentlichen Ihre Bewerbung kostenlos in unserem Partnernetzwerk. Ihre Bewerbung ist automatisch für ein Monat aktiv. Sie können sie jederzeit wieder kostenlos um einen Monat verlängern.</p>
      <form id="jobApplication"
            class="main"
            @submit="onSubmit"
      >
      <div v-if="inputFields.length > 0" class="flex flex-wrap">
        <component :is="field.type"
                   v-for="field in inputFields"
                   :key="'field' + field.id"
                   :ref="'component' + field.id"
                   v-model:formdata="formdata"
                   v-model:validation="validation"
                   :field="field"
                   :is-visible="field.step === 1"
                   mode="form"
                   :option-fields="field.type === 'selectField' || field.type === 'selectFormField' || field.type === 'radioField' ? fields.filter(value => value.parent === field.id_int?? field.id) : []"
                   :postcode="null"
                   :postcodes="field.type === 'zipCityStaticField' || field.type === 'zipCityField' ? postcodes : []"
                   :data-gtm="'field_' + field.id"
        />
      </div>
        <div class="flex justify-center mt-3 px-2">
        <button
                type="submit"
                class="btn btn-lg btn-primary w-full sm:w-auto"
        >
          {{ $t('general.next') }}
        </button>
        </div>
      </form>

    </div>
  </layout>
</template>

<script>

  import { defineComponent } from 'vue';
  import Layout from '../Layout.vue';
  import store from '../store/mainStore';
  import leadFormApi from '../connections/leadForm';
  import ZipCityStaticField from '../formcreator/fieldComponents/zipCityStaticField.vue';
  import EmailStaticField from '../formcreator/fieldComponents/emailStaticField.vue';
  import PhoneStaticField from '../formcreator/fieldComponents/phoneStaticField.vue';
  import ForenameStaticField from '../formcreator/fieldComponents/forenameStaticField.vue';
  import SurnameStaticField from '../formcreator/fieldComponents/surnameStaticField.vue';
  import AddressStaticField from '../formcreator/fieldComponents/addressStaticField.vue';
  import DateField from '../formcreator/fieldComponents/dateField.vue';
  import CheckboxField from '../formcreator/fieldComponents/checkboxField.vue';
  import SelectField from '../formcreator/fieldComponents/selectField.vue';
  import TextareaField from '../formcreator/fieldComponents/textareaField.vue';
  import SalutationStaticField from '../formcreator/fieldComponents/salutationStaticField.vue';

  export default defineComponent({
    name: 'JobApplication',
    components: { Layout, ZipCityStaticField, EmailStaticField, PhoneStaticField, ForenameStaticField, SurnameStaticField, AddressStaticField, DateField, CheckboxField, SelectField, TextareaField, SalutationStaticField },
    data() {
      return {
        fields: [],
        loaded: false,
        locale: 'de-CH',
        validation: {
          form: false,
        },
        formdata: {
          // is important for the registration of the form in the DB
          form_id: 39,
          step: 1,
          postcode: '',
          city: '',
          postcode_id: Number,
          country: '',
          locale: this.locale,
          salutation: '',
          name: '',
          surname: '',
          address: '',
          phone: '',
          email: '',
          date: new Date().format('YYYY-MM-DD'),
          description: '',
          showHide: '',
          dynamic_form: {},
        },
      }
    },
    computed: {
      inputFields() {
        if(this.fields.length > 0) {
        let fields = this.fields;
        fields = fields.filter(obj => obj.parent === null && obj.type !== 'tabsFormField');
        fields.forEach(obj => obj.id_int = obj.id)
        fields.forEach(obj => obj.identification ? obj.id = obj.identification : '')
        return fields;
        } else {
          return []
        }
      },
      postcodes() {
        return store.state.main.postcodes;
      },
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault();
        let form = new FormData();
        Object.keys(this.formdata).forEach(key => key === 'dynamic_form' ? form.append(key, JSON.stringify(this.formdata[key])) : form.append(key, this.formdata[key]));
        const config = {
          headers: { 'content-type': 'multipart/form-data' },
        };
        leadFormApi.post('/job-applications', form, config).then(response => [store.commit('setUser', response.data.content, { root: true }), this.$router.push({ name:'EditJobApplication',  params: { user_id: response.data.content.id }})]);
      },
    },

    created() {
      store.dispatch('getPostcodes', null);
      leadFormApi.get('/forms/multiple-forms-and-fields/35?locale=' + this.locale)
        .then(response => [
          this.fields = response.data.content['fields'][35],
          this.loaded = true])
    }
  });
</script>

<style scoped>

</style>
