<template>
  <div :class="{ 'z-30':(show.datePicker)}" class="relative w-full">
    <VueDatePicker :cancel-text="$t('general.cancel')"
                   :clearable="false"
                   :enable-time-picker="false"
                   :format="formatted"
                   :input-class-name="inputStyles"
                   :locale="activeLocale"
                   :min-date="minDate"
                   :month-change-on-scroll="false"
                   :partial-range="false"
                   :placeholder="placeholder"
                   :select-text="$t('general.select')"
                   @date-update="dateClicked"
                   no-today
                   ref="datepicker"
                   v-model="dateProv"
    />
  </div>
</template>

<script lang="ts" setup>
    // Example using a custom format function
    import {ref} from 'vue';
    import type {DatePickerInstance} from "@vuepic/vue-datepicker"

    const datepicker = ref<DatePickerInstance>(null);

    const date = ref(new Date());
    // In case of a range picker, you'll receive [Date, Date]
    const formatted = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${day}.${month}.${year}`;
    }
    const dateClicked = (date) => {
        if (datepicker) {
            // Close the menu programmatically
            datepicker.value.updateInternalModelValue(date)
            datepicker.value.selectDate()

        }
    }
</script>

<script lang="ts">
    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    import dayjs from 'dayjs'; // ES 2015

    export default {
        name: 'DatePicker',
        components: {VueDatePicker},
        emits: ['update:date'],
        props: {
            inputStyles: String,
            placeholder: String,
            date: {
                type: String,
                default: null,
            },
            dataCy: {
                type: String,
                default: 'change-date',
            },
            pickPast: {
                type: Boolean,
                default: true,
            },
        },
        data() {
            return {
                dateProv: this.date ? dayjs(this.date).format('MM/DD/YYYY') : null,
                selectedDate: this.date ? dayjs(this.date).format('YYYY-MM-DD') : null,
                show: {
                    datePicker: false,
                },
            };
        },
        computed: {
            minDate() {
                return this.pickPast ? null : new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
            },
            activeLocale() {
                if (this.i18n.global.locale && this.i18n.global.locale.length > 0) {
                    return this.i18n.global.locale;
                } else {
                    return 'de-CH'
                }
            },
        },
        watch: {
            dateProv() {
                this.$emit('update:date', dayjs(this.dateProv).format('YYYY-MM-DD'));
            },
        },
        created() {

        },
        methods: {
            // singleClick(event) {
            //   this.$emit('update:date', dayjs(event).format('YYYY-MM-DD'));
            //   this.show.datePicker = false;
            //   this.dateProv = dayjs(event).format(this.format);
            // },
        },
    };
</script>

<style scoped>
    .vuecal__body {
        background-color: white
    }
</style>
