<template>
  <nav class="absolute sm:relative container z-30 top-0 text-white">
    <div class="w-full relative mx-auto bg-black">
      <div class="flex flex-col sm:flex-wrap items-center justify-between lg:flex-row-reverse pb-4 pt-4 sm:pt-10 relative">
        <div class="flex w-full lg:w-auto justify-between px-4 sm:pr-8">
          <div class="pt-1.5 sm:pt-0">
            <router-link v-if="area === 'Company'" :to="{ name: 'PendingLeads' }">
              <img class="h-5" :src="'/' + companyLogo" :alt="appName">
            </router-link>
            <a v-else :href="mainUrl">
              <img class="h-5" :src="'/' + companyLogo" :alt="appName">
            </a>
          </div>

          <div>
            <button type="button" class="text-white lg:hidden" @click="open.mainMenu = ! open.mainMenu">
              <svg class="h-6 w-6 fill-current" viewBox="0 0 24 24">
                <path fill-rule="evenodd"
                      d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              </svg>
            </button>
          </div>
        </div>

        <div class="lg:block w-full lg:w-auto mt-2 lg:mt-0 lg:pl-4" :class="{ 'hidden':(!open.mainMenu), 'flex flex-col':open.mainMenu}">
          <div v-for="item in menu.filter(obj => !obj.meta.childOf)" class="dropdown block lg:inline-block text-sm lg:text-xs lg:text-sm">
            <button v-if="!item.meta.hasOwnProperty('condition') || showRoute(item.meta.condition)"
                    class="menu-item lg:border-r lg:border-white px-6 py-3 lg:py-1"
                    :data-cy="'nav-menu-'+item.name.toLowerCase()"
                    :aria-label="$t('menu.mainMenu.menu')"
                    @click="menu.filter(obj => obj.meta.childOf && obj.meta.childOf === item.name).length === 0 ? goToRoute(item) : mobileSubmenu(item.name)"
            >
              {{ $t(item.meta.menu) }}
            </button>
            <div v-if="!open.mainMenu || open.subMenu.includes(item.name)" class="bg-gray-700">
              <div v-if="menu.filter(obj => obj.meta.childOf && obj.meta.childOf === item.name).length > 0"
                   class="dropdown-content lg:absolute block lg:hidden lg:bg-gray-200 lg:text-black lg:rounded"
              >
                <router-link v-for="subItem in menu.filter(obj => {
                               if (obj.meta.hasOwnProperty('prepaid')) {
                                 return obj.meta.childOf && obj.meta.childOf === item.name && obj.meta.prepaid === company.prepaid;
                               } else {
                                 return obj.meta.childOf && obj.meta.childOf === item.name;
                               }
                             })"
                             :to="{ name: subItem.name }"
                             class="lg:rounded hover:bg-gray-400 py-3 px-8"
                             :data-cy="'nav-menu-'+item.name.toLowerCase()+'-'+subItem.name.toLowerCase()"
                >
                  {{ $t(subItem.meta.menu) }}
                </router-link>
              </div>
            </div>
          </div>

          <button v-if="area === 'Company'"
                  class="menu-item text-left text-sm sm:hidden lg:border-r lg:border-white px-6 py-3 lg:py-1"
                  :aria-label="$t('menu.mainMenu.archive')"
                  @click="$router.push({ name: 'Archive' })"
          >
            {{ $t('menu.mainMenu.archive') }}
          </button>
          <div class="dropdown block lg:inline-block text-sm">
            <div class="relative menu-item px-6 py-2">
              <font-awesome-icon class="lg:absolute lg:-bottom-1 text-xl"
                                 :icon="['fas','user-circle']"
                                 @click="mobileSubmenu('user')"
              />
            </div>
            <div v-if="!open.mainMenu || open.subMenu.includes('user')" class="bg-gray-700">
              <div class="dropdown-content lg:absolute block lg:hidden lg:bg-gray-200 lg:text-black lg:rounded"
                   data-cy="nav-menu-user"
              >
                <div class="lg:rounded hover:bg-gray-400 py-2 px-8" data-cy="div-user-name">{{ userRole.name }}</div>

                <div v-if="area === 'Customer' && userRole.role === 'companyAdmin'">
                  <button v-for="company in companies"
                          :key="company.id"
                          class="w-full text-left lg:rounded hover:bg-gray-400 py-2 px-8"
                          data-cy="manage-company"
                          :aria-label="$t('menu.mainMenu.companySpace')"
                          @click="$router.push({ name: 'PendingLeads', params: { company_id: company.company_id}}).catch(err => {})"
                  >
                    {{ $t('menu.mainMenu.manageCompany', { company: company.company_name }) }}
                  </button>
                </div>

                <div v-if="area === 'Customer'">
                  <button class="w-full text-left lg:rounded hover:bg-gray-400 py-2 px-8"
                          data-cy="manage-company"
                          :aria-label="$t('menu.mainMenu.deleteAccount')"
                          @click="$router.push({ name: 'DeleteAccount', params: { company_id: company.company_id}}).catch(err => {})"
                  >
                    {{ $t('menu.mainMenu.deleteAccount', { company: company.company }) }}
                  </button>
                </div>

                <button v-if="area === 'Company'"
                        class="w-full text-left lg:rounded hover:bg-gray-400 py-2 px-8"
                        data-cy="change-to-privat"
                        :aria-label="$t('menu.mainMenu.privateSpace')"
                        @click="$router.push({ name: 'QuoteOverview', params: { user_id: userRole.user_id }}).catch(err => {})"
                >
                  {{ $t('menu.mainMenu.changeToPrivateAccount') }}
                </button>
                <button v-if="area === 'Public' && userRole.role !== 'guest'"
                        class="w-full text-left lg:rounded hover:bg-gray-400 py-2 px-8"
                        data-cy="show-intern"
                        :aria-label="$t('menu.mainMenu.internal')"
                        @click="$router.push({ name: 'QuoteOverview', params: { user_id: userRole.user_id }}).catch(err => {})"
                >
                  {{ $t('menu.mainMenu.internal') }}
                </button>

                <button v-if="userRole.role !== 'guest'"
                        class="w-full text-left lg:rounded hover:bg-gray-400 py-2 px-8"
                        data-cy="logout"
                        :aria-label="$t('menu.mainMenu.logout')"
                        @click="$router.push('/logout').catch(err => {})"
                >
                  {{ $t('menu.mainMenu.logout') }}
                </button>
                <button v-if="userRole.role === 'guest'"
                        class="w-full text-left lg:rounded hover:bg-gray-400 py-2 px-8"
                        data-cy="show-login"
                        :aria-label="$t('menu.mainMenu.login')"
                        @click="$router.push({ name: 'Login'}).catch(err => {})"
                >
                  {{ $t('menu.mainMenu.login') }}
                </button>
                <button v-if="isAdmin"
                        class="w-full text-left lg:rounded hover:bg-gray-400 py-2 px-8"
                        data-cy="impersonate"
                        :aria-label="$t('menu.mainMenu.impersonate')"
                        @click="impersonate()"
                >
                  {{ $t('menu.mainMenu.impersonate') }} {{ impersonateUserLabel }}
                </button>
                <div class="px-8 sm:hidden">
                  <LocalePicker />
                </div>
              </div>
            </div>
          </div>
          <cart-menu />
          <div v-if="isAdmin" class="dropdown block lg:inline-block text-sm lg:text-xs lg:text-sm">
            <div class="menu-item px-6 py-3 lg:py-1">Logged in as {{ user.name }}</div>
          </div>
        <!--          <font-awesome-icon :icon="['far', 'user-secret']"></font-awesome-icon>-->
        <!--          <button v-for="item in menu.filter(obj => !obj.meta.childOf)" :to="{ name: item.name }" class="  px-3 border-r">{{ item.meta.menu }}</button>-->
        </div>
        <div class="hidden sm:block absolute top-1 right-0 pr-8">
          <LocalePicker />
        </div>
      </div>
      <img class="absolute bottom-0 w-full h-1" src="../../assets/images/color-strip.gif" alt="color-strip">
    </div>
  </nav>
</template>

<script>
  import store from '../store/mainStore';
  import LocalePicker from './LocalePicker.vue';
  import CartMenu from './CartMenu.vue';
  import axios from 'axios';
  import companyApi from '../connections/company';

  export default {
    name: 'MainMenu',
    components: { CartMenu, LocalePicker },
    props: {
      company: Object,
    },
    data() {
      return {
        menu: this.$router.options.routes.filter(obj => obj.meta.area === this.$route.meta.area && obj.meta.menu),
        mainUrl: import.meta.env.VITE_VUE_APP_LANDINGPAGE_URL,
        open: {
          mainMenu: false,
          subMenu: [],
        },

      };
    },
    computed: {
      area() {
        return this.$route.meta.area;
      },
      companies() {
        return store.state.main.companies;
      },
      currentLead() {
        if (this.area === 'Customer') {
          return store.state.customer.currentLead;
        } else return null;
      },
      isAdmin() {
        return store.state.main.user.is_admin;
      },
      user() {
        return store.state.main.user;
      },
      impersonateUserLabel() {
        if (this.$route.params.user_id > 0) {
          return 'user: ' + this.$route.params.user_id;
        }

        if (this.company.id) {
          return 'company: ' + this.company.id;
        }
      },
      userRole() {
        if (store.getters.isAuthenticated && this.menu.length > 0) {
          if (this.menu[0].meta.area === 'Company') {
            return { name: store.state.company.company.name, role: 'company', user_id: store.state.main.user.id };
          } else if (this.menu[0].meta.area === 'Customer' && this.companies && this.companies.length > 0) {
            return { name: store.state.main.user.name, role: 'companyAdmin' };
          } else {
            return { name: store.state.main.user.name, role: 'user', user_id: store.state.main.user.id };
          }
        } else {
          return { name: 'Gast', role: 'guest' };
        }
      },
    },
    methods: {
      mobileSubmenu(name) {
        if (this.open.mainMenu) {
          if (this.open.subMenu.includes(name)) {
            this.open.subMenu = this.open.subMenu.filter(obj => obj !== name);
          } else {
            this.open.subMenu.push(name);
          }
        }
      },
      showRoute(condition) {
        return eval(condition);
      },
      goToRoute(route) {
        if (this.currentLead) {
          this.$router.push({ name: route.name, params: { lead_uuid: this.currentLead.uuid } });
        } else {
          this.$router.push({ name: route.name });
        }
      },
      impersonate() {
        if (this.$route.params.user_id > 0 ){
          this.impersonateUser(this.$route.params.user_id);
        }

        if (this.company.id){
          companyApi.get('/companies/' + this.company.id + '/main-user').then(response => this.impersonateUser(response.data.content.mainUser.id)).catch(error => console.log(error));
        }
      },
      async impersonateUser(userId) {
        const novaApi = axios.create(
          {
            baseURL: import.meta.env.VITE_VUE_APP_BACKEND_URL,
            timeout: 5000,
            headers: {
              'X-Requested-With': 'XMLHttpRequest',
              accept: 'application/json',
              'Content-Type': 'application/json',
            },
            withCredentials: true, // required to handle the CSRF token
            withXSRFToken: true,
            firstTry: true,
          },
        );

        novaApi.post('/nova-api/impersonate', {
          resource: 'users',
          resourceId: userId,
        }).then(response => {
          this.$router.push('/impersonate?action=delete-user-cookie').catch(err => {});
        }).catch(error => {
          console.log(error);
        });
      },
    },
  };
</script>

<style scoped>
    .menu-item {
        text-transform: uppercase;
    }

    .dropdown {
        position: relative;
    }

    .dropdown-content {
        min-width: 220px;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 20;
    }

    .dropdown-content a {
        text-decoration: none;
        display: block;
    }

    .dropdown-content a:hover {
    }

    .dropdown:hover .dropdown-content {
        display: block;
    }

    .dropdown:hover .menu-item {
        color: rgba(253, 230, 138, 1);
    }
</style>

