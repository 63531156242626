<template>
  <div v-for="competitor in competitors" :key="competitor.id" class="bg-gray-100 p-4 m-4 relative">
    <div>{{ competitor.company.label?? competitor.company.name }}</div>
  </div>
</template>
<script>
  import ConfirmationModal from '../../snippets/ConfirmationModal.vue';
  import leadFormApi from '../../connections/leadForm';
  import store from '../../store/mainStore';

  export default {
    name: 'Competitors',
    components: { ConfirmationModal },
    props: {
      companyLead: Object
    },
    data() {
      return {
        competitors: [],
        show: {
          modal: false
        }
      }
    },
    computed: {
      user() {
        return store.state.main.user
      }
    },
    methods: {
    },
    created() {
      leadFormApi.get('leads/'+ this.companyLead.lead_id + '/companies/' + this.companyLead.company_id + '/lead-companies').then(response => this.competitors = response.data.content);
    }
  };
</script>
<style scoped>

</style>
